import React, { useState, useEffect } from 'react';

import { Button, TestsContainer } from "./styles";

import styled from "styled-components";

const ModalContainer = styled.div`
  width: 500px;
  height: 500px;
  background: #fff;

  display: ${({displayModal}) => displayModal ? "block !important" : "none"};
`

const ModalIFrame = styled.iframe`
  width: 100%;
  height: 100%;
` 
function Modal({displayModal, srcIFrame}){
  const [src, setSrc] = useState("")

  useEffect(() => {
    displayModal ? setSrc(srcIFrame) : setSrc("");
  }, [displayModal, srcIFrame])
  return (
    <ModalContainer
      displayModal={displayModal}  
    >
      <ModalIFrame
        src={src}
      />
   </ModalContainer>
  );  
}

function Tests() {
  const [toggleModal, setToggleModal] = useState(false);

  return (
    <TestsContainer>
      <Button
        onClick={() => setToggleModal(!toggleModal)}
      >
        Abrir modal
      </Button>
      <Modal displayModal={toggleModal} srcIFrame="https://agendamento.salaovip.com.br/?slug=azbarbearia&template=1&portal=1" />
    </TestsContainer>
  );
}

export default Tests;