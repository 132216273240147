import styled from "styled-components";

export const TestsContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${props => props.theme.colors.light};

`

export const Button = styled.button`
  padding: 15px;
  border-radius: 10px;
  background: ${props => props.theme.colors.primary};
  color: ${props=> props.theme.colors.light};
  cursor: pointer;

  transition: background-color 0.5s;

  &:hover{
    background: ${props => props.theme.colors.dark};
  }
`